import React, { useContext, useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { CheckCircleOutline, HighlightOff } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '@material-ui/core/Link';
import { AppContext } from '../../../sporttia/AppContext';

const useStyles = makeStyles({
    notificationButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    notificationButtonActive: {
        color: '#18b432',
        '&:hover': {
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
    notificationButtonDisabled: {
        color: '#df3525',
        '&:hover': {
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
});

export default function UserNotifications({ userData }) {
    const cxt = useContext(AppContext);
    const classes = useStyles();
    const [notificationsData, setNotificationsData] = useState();
    const [user, setUser] = useState();

    let notConfGroups = [
        {
            title: cxt.t('Booking'),
            notifications: [
                {
                    id: 16,
                    name: cxt.t('not16.hart.name'),
                    email: true,
                    sms: true,
                    push: true,
                    updatable: [],
                },
            ],
        },
        /* {
            title: cxt.t('games'),
            notifications: [
                {id: 12, name: cxt.t('not12.hart.name'), email: true, sms: true, push: true, updatable: []},
                {id: 3, name: cxt.t('not3.hart.name'), email: true, sms: true, push: true, updatable: []},
                {id: 1, name: cxt.t('not1.hart.name'), email: true, sms: true, push: true, updatable: []},
                {id: 2, name: cxt.t('not2.hart.name'), email: true, sms: true, push: true, updatable: []},
            ]
        }, */
        {
            title: cxt.t('Events'),
            notifications: [
                {
                    id: 14,
                    name: cxt.t('not14.hart.name'),
                    email: true,
                    sms: false,
                    push: true,
                    updatable: [],
                },
                {
                    id: 45,
                    name: cxt.t('not45.hart.name'),
                    email: true,
                    sms: false,
                    push: true,
                    updatable: [],
                },
                {
                    id: 41,
                    name: cxt.t('not41.hart.name'),
                    email: true,
                    sms: true,
                    push: true,
                    updatable: [],
                },
                {
                    id: 56,
                    name: cxt.t('not56.hart.name'),
                    email: true,
                    sms: true,
                    push: true,
                    updatable: [],
                },
            ],
        },
        {
            title: cxt.t('Activities'),
            notifications: [
                {
                    id: 23,
                    name: cxt.t('not23.hart.name'),
                    email: true,
                    sms: false,
                    push: true,
                    updatable: [],
                },
                {
                    id: 10,
                    name: cxt.t('not10.hart.name'),
                    email: true,
                    sms: false,
                    push: true,
                    updatable: [],
                },
                {
                    id: 28,
                    name: cxt.t('not28.hart.name'),
                    email: true,
                    sms: true,
                    push: true,
                    updatable: [],
                },
            ],
        },
        {
            title: cxt.t('Messages'),
            notifications: [
                {
                    id: 18,
                    name: cxt.t('not18.hart.name'),
                    email: true,
                    sms: false,
                    push: true,
                    updatable: [],
                },
            ],
        },
    ];

    useEffect(() => {
        setUser(userData);
    }, [userData]);

    const updateInitialNotificationData = (notifications) => {
        if (notifications) {
            notConfGroups = notConfGroups.map((notConfGroup) => ({
                ...notConfGroup,
                notifications: notConfGroup.notifications.map(
                    (notification) => {
                        const notificationValue = notifications.find(
                            (n) => n.id === notification.id,
                        );
                        return {
                            ...notification,
                            sms: notificationValue.sms,
                            email: notificationValue.email,
                            push: notificationValue.push,
                            updatable: notificationValue.updatable,
                        };
                    },
                ),
            }));
        }

        return notConfGroups;
    };
    const loadNotificationsData = () => {
        cxt.api('GET', `/my/notifications`, {
            success: (r) => {
                setNotificationsData(updateInitialNotificationData(r.notConfs));
            },
        });
    };
    useEffect(() => {
        loadNotificationsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeNotification = (notification, type) => {
        cxt.api('PUT', `/my/notifications/${notification.id}`, {
            params: {
                [type]: !notification[type],
            },
            success: () => {
                loadNotificationsData();
            },
        });
    };

    const changeUserCommunications = () => {
        cxt.api('PUT', '/me', {
            params: {
                commActive: !user.commActive,
            },
            success: (r) => {
                setUser(r.user);
                cxt.showMessage('S', cxt.t('Modified'));
            },
        });
    };

    // -----| Render |-----
    return (
        <>
            {user && user.commActive ? (
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={6}>
                        {cxt.t('Description')}
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        {cxt.t('Email')}
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        SMS
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        App
                    </Grid>
                </Grid>
            ) : (
                ''
            )}

            {user && user.commActive && notificationsData
                ? notificationsData.map((notConfGroup) => (
                      <Box key={notConfGroup?.id} mt={2}>
                          <Typography variant="h5">
                              {notConfGroup.title}
                          </Typography>
                          <Box mb={1}>
                              <Divider />
                          </Box>

                          <Box mt={1}>
                              {notConfGroup.notifications.map((not) => (
                                  <Grid key={not?.id} container spacing={3}>
                                      <Grid item xs={6} sm={6} md={6}>
                                          {not.name}
                                      </Grid>
                                      <Grid item xs={2} sm={2} md={2}>
                                          {not.email ? (
                                              <CheckCircleOutline
                                                  className={`${classes.notificationButton} ${classes.notificationButtonActive}`}
                                                  onClick={() =>
                                                      changeNotification(
                                                          not,
                                                          'email',
                                                      )
                                                  }
                                              />
                                          ) : (
                                              <HighlightOff
                                                  className={`${classes.notificationButton} ${classes.notificationButtonDisabled}`}
                                                  onClick={() =>
                                                      changeNotification(
                                                          not,
                                                          'email',
                                                      )
                                                  }
                                              />
                                          )}
                                      </Grid>

                                      <Grid item xs={2} sm={2} md={2}>
                                          {not.sms ? (
                                              <CheckCircleOutline
                                                  className={`${classes.notificationButton} ${classes.notificationButtonActive}`}
                                                  onClick={() =>
                                                      changeNotification(
                                                          not,
                                                          'sms',
                                                      )
                                                  }
                                              />
                                          ) : (
                                              <HighlightOff
                                                  className={`${classes.notificationButton} ${classes.notificationButtonDisabled}`}
                                                  onClick={() =>
                                                      changeNotification(
                                                          not,
                                                          'sms',
                                                      )
                                                  }
                                              />
                                          )}
                                      </Grid>

                                      <Grid item xs={2} sm={2} md={2}>
                                          {not.push ? (
                                              <CheckCircleOutline
                                                  className={`${classes.notificationButton} ${classes.notificationButtonActive}`}
                                                  onClick={() =>
                                                      changeNotification(
                                                          not,
                                                          'push',
                                                      )
                                                  }
                                              />
                                          ) : (
                                              <HighlightOff
                                                  className={`${classes.notificationButton} ${classes.notificationButtonDisabled}`}
                                                  onClick={() =>
                                                      changeNotification(
                                                          not,
                                                          'push',
                                                      )
                                                  }
                                              />
                                          )}
                                      </Grid>
                                  </Grid>
                              ))}
                          </Box>
                      </Box>
                  ))
                : ''}

            {user && user.commActive ? (
                <Box mt={1}>
                    <Divider />
                </Box>
            ) : (
                ''
            )}

            <Box mt={2}>
                <Link onClick={changeUserCommunications}>
                    {user && user.commActive
                        ? cxt.t('page.user.configuration.noMoreComms')
                        : cxt.t('page.user.configuration.notActive.title')}
                </Link>
            </Box>
        </>
    );
}
